import { Close } from "@mui/icons-material";
import { IconButton, Snackbar } from "@mui/material";
import { PropsWithChildren } from "react";
import { useToaster } from "./toaster.state";

export function ToasterProvider({ children }: PropsWithChildren<unknown>) {
    const { dismissToast, currentToast } = useToaster();

    const { config, content, metadata } = currentToast ?? {};
    const { disableAutoDismiss, disableCloseButton, duration, ...props } =
        config ?? {};
    const { open } = metadata ?? {};

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={disableAutoDismiss ? undefined : duration}
                onClose={dismissToast}
                message={content}
                action={
                    disableCloseButton ? (
                        <></>
                    ) : (
                        <IconButton
                            size="small"
                            onClick={dismissToast}
                            aria-label="close toast"
                            data-testid="spec-close-toast"
                        >
                            <Close fontSize="small" style={{ color: "#eee" }} />
                        </IconButton>
                    )
                }
                {...(props ?? {})}
            />
            {children}
        </>
    );
}
