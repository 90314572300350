import { SnackbarProps } from "@mui/material";
import { ReactNode } from "react";
import { atom, useRecoilState } from "recoil";

type DataAttributes = Record<`data-${string}`, string>;

type ToastConfig = Partial<SnackbarProps> &
    DataAttributes & {
        /** Quantity of milliseconds for toast to stay on the screen visible. Defaults to 5000ms */
        duration: number;
        /** Will remain there until it is closed */
        disableAutoDismiss: boolean;
        /** Will remove the default close button */
        disableCloseButton: boolean;
    };
interface Toast {
    content: ReactNode;
    config: ToastConfig;
    metadata: {
        open: boolean;
    };
}
interface ToasterState {
    currentToast?: Toast;
}
export const toasterState = atom<ToasterState>({
    key: "toaster",
    default: {
        currentToast: undefined,
    },
});

export const useToaster = () => {
    const [state, setState] = useRecoilState(toasterState);

    return {
        ...state,
        dismissToast: () => {
            setState((state) => {
                if (!state.currentToast) return state;
                else
                    return {
                        ...state,
                        currentToast: {
                            ...state.currentToast,
                            metadata: {
                                ...state.currentToast.metadata,
                                open: false,
                            },
                        },
                    };
            });
        },
        toast(content: ReactNode, config?: Partial<ToastConfig>) {
            setState((state) => ({
                ...state,
                currentToast: {
                    config: {
                        ...config,
                        disableAutoDismiss: config?.disableAutoDismiss ?? false,
                        disableCloseButton: config?.disableCloseButton ?? false,
                        duration: config?.duration ?? 5000,
                    },
                    content,
                    metadata: {
                        open: true,
                    },
                },
            }));
        },
    };
};
