import { atom, useRecoilValue } from "recoil";
import { User } from "../hooks/useUser";
import { useRecoilState } from "recoil";

export interface AuthState {
    loggedIn: boolean;
    loading: boolean;
    demoWorkspaceId?: string;
    data?: User;
}

const authDefaultState = {
    loggedIn: false,
    loading: true,
    data: undefined,
};

export const authState = atom<AuthState>({
    key: "auth",
    default: authDefaultState,
});

export const useAuth = () => {
    const [auth] = useRecoilState(authState);
    return auth as AuthState;
};

export const useSetAuth = () => {
    const [, setAuth] = useRecoilState(authState);
    return setAuth;
};

export const useUpdateAuth = () => {
    const [, setAuth] = useRecoilState(authState);
    return (newAuth: Partial<AuthState>) =>
        setAuth((auth) => ({ ...auth, ...newAuth }));
};

export const useCurrentWorkspace = () => {
    const { data } = useRecoilValue(authState);
    const demoWorkspace = sessionStorage.getItem("demoWorkspace");
    return demoWorkspace && demoWorkspace !== "undefined"
        ? JSON.parse(sessionStorage.getItem("demoWorkspace")!)
        : data?.current_workspace;
};

export const useDemoWorkspace = () => {
    const { demoWorkspaceId } = useRecoilValue(authState);
    return demoWorkspaceId;
};

export const useCurrentVertical = () => {
    const { data } = useRecoilValue(authState);
    return data?.current_workspace?.vertical;
};

export default authState;
