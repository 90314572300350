import { AxiosError } from "axios";
import { ReactNode, useRef } from "react";
import { DefaultOptions, QueryClient, QueryClientProvider } from "react-query";

export function isAxiosError(err: any): err is AxiosError {
    return "response" in err;
}

export const makeQueryClient = () => {
    const defaultOptions: DefaultOptions = {
        queries: {
            staleTime: Infinity,
            retry: (_times, error) => {
                if (
                    isAxiosError(error) &&
                    (error.response?.status === 403 ||
                        error.response?.status === 401)
                ) {
                    return false;
                }
                return true;
            },
        },
    };

    return new QueryClient({
        defaultOptions,
    });
};

interface Props {
    children?: ReactNode;
}

export default function TestboxQueryClientProvider({ children }: Props) {
    const queryClient = useRef(makeQueryClient());
    return (
        <QueryClientProvider client={queryClient.current}>
            {children}
        </QueryClientProvider>
    );
}
