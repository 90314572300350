import { ReactNode } from "react";
import { Flex, text } from "@testboxlab/design-system";
import { CenteredPage } from "@testboxlab/design-system/dist/components/CenteredPage/CenteredPage";
import { H3, Body1 } from "@testboxlab/design-system/dist/components/Text/Text";
import TestBoxLogo from "../../assets/logo@3x.png";
import { topBarHeight } from "../frame/NavToolbar.styles";

interface Props {
    className?: string;
    text?: ReactNode;
}

const sunsetText = (
    <Flex
        column
        color={text.secondary}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={5}
    >
        <H3 fontWeight={500} mb={2}>
            We’re shifting priorities...
        </H3>
        <Body1 textAlign={"center"}>
            The TestBox Compare experience has sunsetted.
            <br />
            If you have any questions, contact us at hello@testbox.com.
        </Body1>
    </Flex>
);

export const TestBoxNotEnabled = ({ className, text = sunsetText }: Props) => {
    return (
        <Flex
            flex={"auto"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            marginTop={`${topBarHeight}px`}
            height={`calc(100vh - ${topBarHeight}px)`}
        >
            <CenteredPage innerFlexProps={{ padding: 0 }}>
                <Flex column alignItems={"center"} justifyContent={"center"}>
                    <a href="https://testbox.com/" target="_blank">
                        <img
                            src={TestBoxLogo}
                            alt="TestBox Logo"
                            height={70.15}
                        />
                    </a>
                    <Flex className={className}>{text}</Flex>
                </Flex>
            </CenteredPage>
        </Flex>
    );
};
