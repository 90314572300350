import { alpha, Theme } from "@mui/material/styles";
import { createStyles, makeStyles, withStyles } from "@mui/styles";

import { theme } from "@testboxlab/design-system/dist/base/theme";
import { green } from "@testboxlab/design-system/dist/base/colors";
import MaterialLink from "@mui/material/Link";
import Container from "@mui/material/Container";

export const topBarHeight = 64;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(0),
            paddingTop: theme.spacing(0),
            paddingRight: theme.spacing(1),
            flexGrow: 1,
            height: topBarHeight,
        },
        appBar: {
            backgroundColor: green.standard,
            color: "black",
            zIndex: theme.zIndex.drawer + 5,
            boxShadow: "none",
            [theme.breakpoints.up("sm")]: {
                boxShadow: "0px 2px 12px rgba(174, 193, 193, 0.5)",
            },
        },
        toolbar: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        hide: {
            display: "none",
        },
        indicator: {
            backgroundColor: alpha(green.light, 0.2),
            opacity: 0.2,
        },
        cursorPointer: {
            cursor: "pointer",
        },
        whiteOutlinedButton: {
            color: "#fff",
            backgroundColor: "transparent",
            border: "1px solid #fff",
            lineHeight: 1.3,
            "&:hover": {
                backgroundColor: "#fff",
                color: green.standard,
            },
        },
    })
);

export const Link = withStyles({
    root: {
        cursor: "pointer",
    },
})(MaterialLink);

export const LogoContainer = withStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
})(Container);
