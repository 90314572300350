import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import _ from "lodash";

export const ENV_KEY = "ENV";

export interface Environment {
    GOOGLE_OAUTH_CLIENT_ID: string;
    LAUNCH_DARKLY_CLIENT_ID: string;
    LAUNCH_DARKLY_FLAGS: string;
    MIXPANEL_API_KEY: string;
    DISALLOWED_EMAILS: string[];
    CATALYST_HUBSPOT_FORM_ID: string;
    CATALYST_HUBSPOT_PORTAL_ID: string;
    DATASET_EDITOR_URL: string;
}

// if first time loading, we have to return undefined
// so that the LaunchDarkly component does not preemptively go "ready"
export const getEnvFromLocal = (): Environment | undefined => {
    let envString: string = "";
    try {
        envString = localStorage.getItem(ENV_KEY) || "";
    } catch (e) {}
    try {
        const parsedEnv = JSON.parse(envString);
        _.forEach(parsedEnv, (v, k) => {
            if (!window[ENV_KEY]) {
                window[ENV_KEY] = {};
            }
            if (typeof window == "object") {
                // @ts-ignore
                window[ENV_KEY][k] = v;
            }
        });

        return parsedEnv;
    } catch (e) {}
};

const defaultState = getEnvFromLocal();
const state = atom<Environment | undefined>({
    key: "env",
    default: defaultState,
});

export const useEnv = () => {
    return useRecoilValue(state);
};

export const useSetEnv = () => {
    return useSetRecoilState(state);
};

export default state;
